
.backgroundDiv
{
  position:fixed;
  bottom: 0;
  top:  0;
  left: 0;
  right:0;
  background-image: url("Images/Background.png");
  filter: blur(8px);
  -webkit-filter: blur(8px);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  z-index: -1;
}
body::-webkit-scrollbar 
{
    display: none;
}
.custom-scrollbar 
{
    direction: rtl;
    text-align: left;
}
::-webkit-scrollbar
{
    width: 20px;
}
::-webkit-scrollbar-thumb 
{
    background-color: #d6dee1;
    border-radius: 80px;
    border: 8px solid transparent;
    background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover 
{
    background-color: #a8bbbf;
}
.MainComp
{
    position: absolute;
    top: 10%;
}
.fitt
{
    height: 100%;
    width: 100%;
}
.centered {
    position: relative;
    flex-direction: column;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
.FullWidth
{
    width: 100%;
}